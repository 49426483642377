import React from 'react'
import { LoadingSpinner } from '../components'

export default function Loading() {

    return (
        <>
            <LoadingSpinner />
        </>
    )
}  
 