import React from 'react'
import { Impressum, ScrollToTop } from '../components'

export default function Privacy() {

    return (
        <>
            <ScrollToTop />
            <Impressum />
        </>
    )
}  
 